
import _ from "lodash"
import {
    DownloadOutlined,
} from '@ant-design/icons-vue';
import {
    base64ToBlob
} from "@/utils/common"
export default {
    components: {
        DownloadOutlined
    },
    data() {
        return {
            //url: `/admin/express_invoice/reviews/${this.$route.query.id}`,
            where: {
                keywords: this.$route.query?.keywords
            },
            selection: [],
            spinning: false,
            exportLoading: false
        };
    },
    computed: {
        url() {
            return `/admin/express_invoice/master_reviews/${this.$route.query.id}`;
        },
        columns() {
            let expaned = {}

            if (['mz-gj.lbl.morelink56.com'].includes(this.$setting.env)) {
                expaned['mz-gj.lbl.morelink56.com'] = [{
                        title: this.$t("reference1"),
                        dataIndex: "reference1",
                        sorter: true,
                        width: 150
                    },
                    {
                        title: this.$t("reference2"),
                        dataIndex: "reference2",
                        sorter: true,
                        width: 150
                    }
                ]
            }

            return [{
                    title: this.$t("公司名称"),
                    dataIndex: "company_name",
                    sorter: true,
                    width: 120
                },
                {
                    title: this.$t('订单号'),
                    dataIndex: 'po_number',
                    width: 200,
                    sorter: true,
                    slots: {
                        customRender: 'po_number'
                    }
                },
                {
                    title: this.$t("主单号"),
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    width: 200
                },
                ...(expaned['mz-gj.lbl.morelink56.com'] || []), 
                {
                    title: this.$t('包裹'),
                    dataIndex: 'package_count',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t("账单包裹"),
                    dataIndex: "invoicepages_counts",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("订单费用"),
                    dataIndex: "order_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("服务费"),
                    dataIndex: "service_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("账单费用"),
                    dataIndex: "invoice_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("账单服务费"),
                    dataIndex: "invoice_servicecharge",
                    sorter: true,
                    width: 120
                },
                {
                    title: this.$t("差值"),
                    dataIndex: "diff",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t('状态'),
                    dataIndex: 'order_status',
                    width: 120,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t('订单日期'),
                    dataIndex: 'order_date',
                    width: 150,
                    sorter: true,
                    align: 'center'
                },
                {
                    key: "action",
                    title: this.$t("操作"),
                    fixed: "right",
                    slots: {
                        customRender: "action",
                    }
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('theme/tabSetTitle', {
            fullPath: this.$route.fullPath,
            title: `${this.$t('对账')} (${this.$route.query.number})`
        });
    },
    methods: {
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http.get(`/admin/express_invoice/export_master_reviews/${this.$route.query.id}`, {
                params: this.where
            }).then(res => {
                this.exportLoading = false;

                if (res.data.code === 0) {
                    const blob = base64ToBlob(res.data.data, 'application/vnd.ms-excel');
                    const fileName = `Invoices-${new Date().getTime()}.xls`;

                    if ("download" in document.createElement("a")) {
                        // 非IE下载
                        const el = document.createElement("a");

                        el.download = fileName;
                        el.style.display = "none";
                        el.href = URL.createObjectURL(blob);
                        document.body.appendChild(el);

                        el.click();

                        URL.revokeObjectURL(el.href);
                        document.body.removeChild(el);
                    } else {
                        // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.exportLoading = false;
                this.$message.error(e.message);
            });
        },
        /**
         * 刷新表格
         */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};

            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 推送账单
         */
        pushAll: _.debounce(function (rows, key) {
            this.spinning = true;
            switch (key) {
                case 'all':
                    this.$http.post(`/admin/express_invoice/master_push/${this.$route.query.id}`, {
                        ids: []
                    }).then(res => {
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                    }).finally(() => {
                        this.spinning = false;
                    })
                    break;
                default:
                    this.$http.post(`/admin/express_invoice/master_push/${rows[0].invoice_id}`, {
                        ids: rows.map(a => a.order_id)
                    }).then(res => {
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                    }).finally(() => {
                        this.spinning = false;
                    })
                    break;
            }
        }, 300),
        /**
         * 删除账单
         */
         deleteAll: _.debounce(function (rows) {
            this.spinning = true;
            this.$http.post(`/admin/express_invoice/deletemaster_push/${rows[0].invoice_id}`, {
                ids: rows.map(a => a.order_id)
            }).then(res => {
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            }).finally(() => {
                this.spinning = false;
            })
        }, 300)
    }
};
